import Link from 'next/link'

import { ConditionalWrapper } from '@/components/shared/conditional-wrapper'
import { cn } from '@/lib/utils'

import { LogoWordmark } from './logo-wordmark'

type Props = {
  href?: string
  hasLink?: boolean
  showWordmark?: boolean
  className?: string
}

export const LogoLink = ({
  href = '/',
  hasLink = true,
  showWordmark = true,
  className,
}: Props) => {
  return (
    <ConditionalWrapper
      condition={hasLink}
      wrapper={(children) => (
        <Link href={href} className="flex items-center">
          {children}
        </Link>
      )}
    >
      <div
        className={cn('inline-flex items-center', className)}
        title="Fingertip"
      >
        <LogoWordmark showWordmark={showWordmark} />
      </div>
    </ConditionalWrapper>
  )
}
