'use client' // Error components must be Client components

import Link from 'next/link'

import { LogoLink } from '@/components/shared/logo-link'

const linkClassName = 'text-primary underline-offset-2 hover:underline'

export default function NotFound() {
  return (
    <div className="container mx-auto px-4 pb-8 pt-4 lg:max-w-2xl">
      <div className="mb-8">
        <LogoLink />
      </div>

      <h1 className="h1 mb-1">Oops!</h1>
      <h2 className="h3 mb-4">
        We can't seem to find the page you're looking for.
      </h2>

      <p className="mb-2">Here are some helpful links instead:</p>

      <ul className="space-y-2">
        <li>
          <Link href="/" className={linkClassName}>
            Home
          </Link>
        </li>
        <li>
          <Link href="/sites" className={linkClassName}>
            All sites
          </Link>
        </li>
        <li>
          <Link href="/my/dashboard" className={linkClassName}>
            Customer dashboard
          </Link>
        </li>
        <li>
          <Link href="/account" className={linkClassName}>
            Account settings
          </Link>
        </li>
      </ul>
    </div>
  )
}
