import * as React from 'react'
import { SVGProps } from 'react'

export const LogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={137}
    height={114}
    viewBox="0 0 137 114"
    fill="none"
    {...props}
  >
    <g fill="#F95828" filter="url(#a)">
      <path d="M78.172 69.429c-.012-1.386-.194-2.837-.65-4.139-1.61-4.59-6.359-7.238-11.125-6.357-4.664.865-8.088 4.967-8.096 9.82-.017 12.254-.011 24.508-.009 36.761a7.321 7.321 0 0 1-14.641 0c-.003-12.506-.011-25.01.026-37.516.004-1.677.218-3.383.562-5.027C46.7 51.166 57.877 42.836 70.007 43.709c12.086.869 22.074 10.609 22.648 22.624.234 4.867.287 13.24.299 19.375.008 4.144-3.448 7.602-7.592 7.602-3.948 0-7.175-3.148-7.19-7.095-.021-5.596.051-11.194 0-16.79" />
      <path d="M129.536 93.313c-4 0-7.158-3.136-7.162-7.134-.006-5.362-.016-12.454-.074-16.585-.045-3.043-.195-6.123-.716-9.114-5.032-28.654-30.756-47.94-59.589-44.77-24.628 2.703-44.544 22.534-47.446 47.268-.425 3.596-3.33 6.386-6.952 6.386h-.545c-4.169 0-7.405-3.378-7.021-7.528.16-1.722.347-3.383.552-4.544C7.758 16.788 49.287-8.77 88.443 3.832c26.666 8.583 42.495 27.356 47.697 54.947.736 3.908.859 18.18.86 27.107.001 4.118-3.346 7.427-7.464 7.427Z" />
      <path d="M100.072 68.94a.004.004 0 0 1-.004-.005c-.016-1.732-.093-3.477-.32-5.19-2.408-18.073-20.23-30.553-38.044-26.673-15.024 3.274-25.327 16.105-25.356 31.653-.023 12.24-.007 24.479-.002 36.718a7.315 7.315 0 0 1-7.243 7.317 7.322 7.322 0 0 1-7.394-7.317c-.008-12.098-.025-24.195.04-36.29.011-2.849.278-5.726.739-8.543 4.09-24.944 28.91-42.314 53.78-37.707 22.608 4.19 38.445 23.206 38.453 46.17 0 5.243.054 25.345.1 36.475.017 4.087-3.382 7.512-7.47 7.512a7.146 7.146 0 0 1-7.146-7.12l-.129-36.997c0-.002-.001-.004-.004-.004Z" />
    </g>
    <defs>
      <filter
        id="a"
        width={137}
        height={120.642}
        x={0}
        y={0.597}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={8.179} />
        <feGaussianBlur stdDeviation={5.112} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.46 0" />
        <feBlend in2="shape" result="effect1_innerShadow_658_4648" />
      </filter>
    </defs>
  </svg>
)
