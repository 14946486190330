import { LogoWordmarkIcon } from '@fingertip/icons'

import { LogoIcon } from '@/components/shared/logo-icon'

type Props = {
  showWordmark?: boolean
}

export const LogoWordmark = ({ showWordmark = true }: Props) => {
  return (
    <div className="flex space-x-1 xs:space-x-[5px]">
      <LogoIcon className=" h-[19.2px] w-[23.376px] shrink-0 xs:h-[24px] xs:w-[29.22px]" />
      {showWordmark && (
        <LogoWordmarkIcon className="relative top-[4px] h-[16px] w-[97.566px] shrink-0 xs:h-[20px] xs:w-[121.957px]" />
      )}
    </div>
  )
}
